// import { createMuiTheme } from "@mui/material";
import { createTheme } from '@mui/material/styles'
import { adaptV4Theme } from '@mui/material/styles';
//import purple from '@mui/material/colors/purple';
// import green from '@mui/material/colors/green';
// import grey from '@mui/material/colors/grey';
import blueGrey from '@mui/material/colors/blueGrey';
import deepPurple from '@mui/material/colors/deepPurple';
// import lightGreen from '@mui/material/colors/lightGreen';

import NightmarePillsWoff2 from '../fonts/NIGHTMAREPILLS-Regular.woff2';

const nightmarepills = {
  fontFamily: 'NightmarePills',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${NightmarePillsWoff2}) format('woff2')
  `,
}

const theme = createTheme(adaptV4Theme({
    palette: {
      mode: "dark",
      background: {
        // https://material.io/design/color/dark-theme.html#properties
        default: "#121212", // dp00
        paper: "#1e1e1e"    // dp01
      },
      primary: blueGrey,
      secondary: deepPurple,
    },
    typography: {
       //fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      h2: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h3: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h4: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h5: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      }
    },
    overrides: {
      // Style sheet name ⚛️
      MuiPaper: {
        // Name of the rule
        elevation2:  {"background-color": '#222222',}, //dp02
        elevation3:  {"background-color": '#242424',}, //dp03
        elevation4:  {"background-color": '#272727',}, //dp04
        elevation6:  {"background-color": '#2C2C2C',}, //dp06
        elevation8:  {"background-color": '#2D2D2D',}, //dp08
        elevation12: {"background-color": '#323232',}, //dp12
        elevation16: {"background-color": '#353535',}, //dp16
        elevation24: {"background-color": '#383838',}, //dp24
      },
      // MuiCssBaseline: {
      //   '@global': {
      //     '@font-face': [nightmarepills],
      //   },
      // },
    },
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: `
    //       @font-face {
    //         fontFamily: 'NightmarePills';
    //         fontStyle: 'normal';
    //         fontDisplay: 'swap';
    //         fontWeight: 400;
    //         src: url(${NightmarePillsWoff2}) format('woff2');
    //       }
    //     `
    //   },
    // },
}));


  export default theme;
  